















































































































































































import Vue from 'vue';
import { computed, defineComponent, reactive, ref, watch } from '@vue/composition-api';
import { createNamespacedHelpers } from 'vuex-composition-helpers';
import PrintFooter from '../../../components/print/print-footer.vue';
import PrintHeader from '../../../components/print/print-header.vue';
import DatePicker from 'vue2-datepicker';
import moment from 'moment';
import { ACL } from '../../../modules/acl/acl-service';
import { IForm1Transporter, IRouteSubtype, ITransporter } from '../../../types';
import { ModelSelect } from 'vue-search-select';
import { RouteSubtype } from '../../../services/api/route-subtype';
import { Transporter } from '../../../services/api/transporter';
import { RoadFacilitiesForm1 } from '../../../services/api/report/road-facilities/form1';
import { ROAD_FACILITIES_TYPE_GROUP } from '../../../constants/RouteTypeGroups';
import { PROJECT_TITLE } from '@/constants/Global';

const municipalitiesHelpers = createNamespacedHelpers('municipalities');

export default defineComponent({
  name: 'road-facilities-form1',
  components: {
    PrintHeader,
    PrintFooter,
    DatePicker,
    ModelSelect,
  },
  setup(props, { root }) {
    const printing = ref(false);
    const currentDate = computed(() => moment(filter.date).format('DD.MM.YYYY'));
    const groupedData = computed(() => data.value
      .reduce((acc, current) => {
        if (!current.transporterId) {
          return acc;
        }

        if (!(acc as any)[current.transporterId]) {
          (acc as any)[current.transporterId] = [];
        }
        (acc[current.transporterId] as any).push(current);

        return acc;
      }, {} as { [key: number]: Array<Partial<IForm1Transporter>> }));
    const filteredTransporters = computed(() => ([
      {
        text: 'Название организации',
      },
      ...data.transporters.map(r => ({
        value: r.id,
        text: r.name,
      })),
    ]));
    const filteredRouteSubtype = computed(() => ([
      {
        text: 'Вид использования',
      },
      ...data.routeSubtypes.map(r => ({
        value: r.id,
        text: r.name,
      })),
    ]));
    const { current, isCurrentGeneral } = municipalitiesHelpers.useGetters(['current', 'isCurrentGeneral']);
    const { setCurrentMunicipality } = municipalitiesHelpers.useActions(['setCurrentMunicipality']);
    const filter: {
      date: string | Date,
      timeFrom: string | Date,
      timeTo: string | Date,
      transporter: {
        value?: number,
        text?: string,
      },
      routeSubtype: {
        value?: number,
        text?: string,
      },
    } = reactive({
      date: '',
      timeFrom: '',
      timeTo: '',
      transporter: {},
      routeSubtype: {},
    });
    const canExport = ref(false);
    const canPrint = ref(false);
    const data: {
      value: Array<Partial<IForm1Transporter>>,
      sum: Partial<Omit<IForm1Transporter, 'name'>>,
      sumByTransporter: Array<Partial<Omit<IForm1Transporter, 'name' >>>,
      routeSubtypes: Array<IRouteSubtype>,
      transporters: Array<ITransporter>,
    } = reactive({
      value: [],
      sum: {},
      sumByTransporter: [],
      routeSubtypes: [],
      transporters: [],
    });
    const headers: {
      [key in keyof Omit<IForm1Transporter, 'transporterId' | 'name'>]: string
    } = {
      municipality: 'МО',
      vehicleNumberRnis: 'Количество дорожной техники в РНИС',
      vehicleNumberOnline: 'Количество дорожной техники Онлайн',
      vehicleNumberFact: 'Количество дорожной техники по факту на линии',
      sumLineRun: 'Общий пробег на линии, км',
      sumRun: 'Общий пробег, км',
      timeStart: 'Время начала работы',
      timeEnd: 'Время окончания работы',
      averageSpeed: 'Средняя скорость на линии, км/ч',
      stopsOnTheLineNumber: 'Количество стоянок на линии более 5 минут',
      speedViolationNumber: 'Количество нарушений скоростного режима',
      trackerFaultNumber: 'Количество сбоев трекеров ГЛОНАСС',
    };
    const notSharedHeaders = computed(() => {
      const answer: {
        [key in keyof Omit<IForm1Transporter, 'transporterId' | 'name'>]?: string
      } = {};

      Object.keys(headers).forEach((key) => {
        if (sharedColumns.indexOf(key) === -1) {
          answer[key as keyof Omit<IForm1Transporter, 'transporterId' | 'name'>] = headers[key as keyof Omit<IForm1Transporter, 'transporterId' | 'name'>];
        }
      });

      return answer;
    });
    const sumHeaders = Object.keys(headers).filter(k => k !== 'municipality');

    const formatDate = (date: string | null) => {
      return date ? moment.utc(date).local().format(dateParseFormat) : '—';
    };

    const dateParseFormat = '<\\div \\s\\t\\y\\l\\e="\\f\\o\\n\\t-\\s\\i\\z\\e: 10\\p\\x; \\c\\o\\l\\o\\r: \\g\\r\\a\\y;">DD.MM.YY</\\d\\i\\v><\\d\\i\\v>HH:mm</\\d\\i\\v>';

    const getTotalSum = (data: Array<Partial<IForm1Transporter>>) => {
      return data.reduce((acc, transporter) => {
        Object.keys(acc)
          .forEach((key) => {
            if (transporter[key as keyof IForm1Transporter]) {
              switch (key) {
                case 'averageSpeed':
                  (acc as any)[key] = (acc as any)[key] ? Math.floor(((acc as any)[key] + Number(transporter[key as keyof IForm1Transporter])) / 2) : Number(transporter[key as keyof IForm1Transporter]);
                  break;
                case 'timeStart':
                  if (!(acc as any)[key] || (acc as any)[key] === '—') {
                    (acc as any)[key] = transporter[key as keyof IForm1Transporter] as string;
                  } else {
                    const oldTime = moment((acc as any)[key] as string, dateParseFormat);
                    const newTime = moment(transporter[key as keyof IForm1Transporter] as string, dateParseFormat);

                    if (newTime.isBefore(oldTime)) {
                      (acc as any)[key] = newTime.format(dateParseFormat);
                    }
                  }
                  break;
                case 'timeEnd':
                  if (!(acc as any)[key] || (acc as any)[key] === '—') {
                    (acc as any)[key] = transporter[key as keyof IForm1Transporter] as string;
                  } else {
                    const oldTime = moment((acc as any)[key] as string, dateParseFormat);
                    const newTime = moment(transporter[key as keyof IForm1Transporter] as string, dateParseFormat);

                    if (newTime.isAfter(oldTime)) {
                      (acc as any)[key] = newTime.format(dateParseFormat);
                    }
                  }
                  break;
                default:
                  (acc as any)[key] += Number(transporter[key as keyof IForm1Transporter]);
                  break;
              }
            }
          });

        return acc;
      }, {
        vehicleNumberRnis: 0,
        vehicleNumberOnline: 0,
        vehicleNumberFact: 0,
        sumLineRun: 0,
        sumRun: 0,
        averageSpeed: 0,
        stopsOnTheLineNumber: 0,
        speedViolationNumber: 0,
        trackerFaultNumber: 0,
        timeStart: '',
        timeEnd: '',
      });
    };

    const getSum = (data: Array<Partial<IForm1Transporter>>) => {
      return data.reduce((acc, transporter) => {
        Object.keys(acc)
          .forEach((key) => {
            if (transporter[key as keyof IForm1Transporter]) {
              switch (key) {
                case 'averageSpeed':
                  (acc as any)[key] = (acc as any)[key] ? Math.floor(((acc as any)[key] + Number(transporter[key as keyof IForm1Transporter])) / 2) : Number(transporter[key as keyof IForm1Transporter]);
                  break;
                case 'timeStart':
                  if (!(acc as any)[key] || (acc as any)[key] === '—') {
                    (acc as any)[key] = transporter[key as keyof IForm1Transporter] as string;
                  } else {
                    const oldTime = moment((acc as any)[key] as string, dateParseFormat);
                    const newTime = moment(transporter[key as keyof IForm1Transporter] as string, dateParseFormat);

                    if (newTime.isBefore(oldTime)) {
                      (acc as any)[key] = newTime.format(dateParseFormat);
                    }
                  }
                  break;
                case 'timeEnd':
                  if (!(acc as any)[key] || (acc as any)[key] === '—') {
                    (acc as any)[key] = transporter[key as keyof IForm1Transporter] as string;
                  } else {
                    const oldTime = moment((acc as any)[key] as string, dateParseFormat);
                    const newTime = moment(transporter[key as keyof IForm1Transporter] as string, dateParseFormat);

                    if (newTime.isAfter(oldTime)) {
                      (acc as any)[key] = newTime.format(dateParseFormat);
                    }
                  }
                  break;
                case 'vehicleNumberRnis':
                case 'vehicleNumberOnline':
                  (acc as any)[key] = Number(transporter[key as keyof IForm1Transporter]);
                  break;
                default:
                  (acc as any)[key] += Number(transporter[key as keyof IForm1Transporter]);
                  break;
              }
            }
          });

        return acc;
      }, {
        vehicleNumberRnis: 0,
        vehicleNumberOnline: 0,
        vehicleNumberFact: 0,
        sumLineRun: 0,
        sumRun: 0,
        averageSpeed: 0,
        stopsOnTheLineNumber: 0,
        speedViolationNumber: 0,
        trackerFaultNumber: 0,
        timeStart: '',
        timeEnd: '',
      });
    };

    const getData = () => {
      if (!filter.date) {
        return;
      }

      let query = {
        municipalityId: current.value.id !== 0 ? current.value.id : undefined,
        transporterId: filter.transporter.value,
        routeSubtypeId: filter.routeSubtype.value,
        date: moment(filter.date).format('YYYY-MM-DD'),
        timeFrom: filter.timeFrom ? moment(filter.timeFrom).format('HH:mm') : '',
        timeTo: filter.timeTo ? moment(filter.timeTo).format('HH:mm') : '',
      };

      if (root.$route.query.transporterId?.toString() !== query.transporterId?.toString() ||
        root.$route.query.municipalityId?.toString() !== query.municipalityId?.toString() ||
        root.$route.query.date !== query.date ||
        root.$route.query.timeFrom !== query.timeFrom ||
        root.$route.query.timeTo !== query.timeTo ||
        root.$route.query.routeSubtypeId?.toString() !== query.routeSubtypeId?.toString()) {
        root.$router.replace({
          query: query as any,
        }).catch(() => {});
      }

      RoadFacilitiesForm1.getData(query)
        .then((response: Array<IForm1Transporter>) => {
          data.value = [...response.map(t => ({
            ...t,
            timeStart: formatDate(t.timeStart),
            timeEnd: formatDate(t.timeEnd),
          }))];

          data.sumByTransporter = Object.values(groupedData.value)
            .map(group => getSum(group));
          data.sum = getTotalSum(data.sumByTransporter);

          canExport.value = ACL.can('report_export.form1');
          canPrint.value = true;
        });
    };

    if (Object.keys(root.$route.query).length) {
      const routeQuery = root.$route.query;

      const date = routeQuery.date as string;
      if (/\d{4}-\d{2}-\d{2}/.test(date)) {
        filter.date = moment(date).toDate();
      }

      const timeFrom = routeQuery.timeFrom as string;
      if (timeFrom && filter.date) {
        filter.timeFrom = moment(date + ' ' + timeFrom).toDate();
      }

      const timeTo = routeQuery.timeTo as string;
      if (timeTo && filter.date) {
        filter.timeTo = moment(date + ' ' + timeTo).toDate();
      }

      const municipalityId = routeQuery.municipalityId;
      if (municipalityId) {
        setCurrentMunicipality(Number(municipalityId));
      }

      const transporterId = routeQuery.transporterId;
      if (transporterId) {
        filter.transporter = {
          value: Number(transporterId),
        };
      }

      const routeSubtypeId = routeQuery.routeSubtypeId;
      if (routeSubtypeId) {
        filter.routeSubtype = {
          value: Number(routeSubtypeId),
        };
      }
    }

    const init = () => {
      RouteSubtype.getAll()
        .then((routeSubtypes: Array<IRouteSubtype>) => {
          data.routeSubtypes = [...routeSubtypes];

          if (filter.routeSubtype.value) {
            filter.routeSubtype.text = data.routeSubtypes.find(r => Number(r.id) === Number(filter.routeSubtype.value))?.name;
          }
        });

      Transporter.getTransporters({
        all: 1,
        filter: {
          municipality: current.value.id !== 0 ? current.value.id : undefined,
          typeGroup: ROAD_FACILITIES_TYPE_GROUP,
        },
      })
        .then((response: {
          transporters: Array<ITransporter>
        }) => {
          data.transporters = [...response.transporters];

          if (filter.transporter.value) {
            filter.transporter.text = data.transporters.find(r => Number(r.id) === Number(filter.transporter.value))?.name;
          }
        });

      getData();
    };
    init();

    const historyDateParams = computed(() => {
      const date = moment(filter.date);
      const dateFromObject = moment(date.startOf('day'));
      const dateToObject = moment(date.endOf('day'));
      let dateFrom = dateFromObject.format('YYYY-MM-DD HH:mm:ss');
      let dateTo = dateToObject.format('YYYY-MM-DD HH:mm:ss');

      if (filter.timeTo && filter.timeFrom) {
        const timeTo = moment(date.format('YYYY-MM-DD') + ' ' + moment(filter.timeTo).format('HH:mm:ss'));
        const timeFrom = moment(date.format('YYYY-MM-DD') + ' ' + moment(filter.timeFrom).format('HH:mm:ss'));

        if (timeFrom.isSameOrAfter(timeTo)) {
          dateToObject.add(1, 'days');
        }

        dateFrom = `${dateFromObject.format('YYYY-MM-DD')} ${timeFrom.format('HH:mm:ss')}`;
        dateTo = `${dateToObject.format('YYYY-MM-DD')} ${timeTo.format('HH:mm:ss')}`;
      } else if (filter.timeTo) {
        const timeTo = moment(filter.timeTo);
        dateTo = `${dateToObject.format('YYYY-MM-DD')} ${timeTo.format('HH:mm:ss')}`;
      } else if (filter.timeFrom) {
        const timeFrom = moment(filter.timeFrom);
        dateFrom = `${dateFromObject.format('YYYY-MM-DD')} ${timeFrom.format('HH:mm:ss')}`;
      }

      return {
        dateTo,
        dateFrom,
        dateToFormatted: moment(dateTo).format('DD.MM.YYYY HH:mm'),
        dateFromFormatted: moment(dateFrom).format('DD.MM.YYYY HH:mm'),
      };
    });

    watch(current, () => init());
    watch(filter, () => getData());

    const sharedColumns = ['vehicleNumberRnis', 'vehicleNumberOnline'];

    return {
      groupedData,
      data,
      headers,
      sumHeaders,
      filter,
      printing,
      currentDate,
      current,
      isCurrentGeneral,
      canExport,
      canPrint,
      filteredTransporters,
      filteredRouteSubtype,
      formatDate,
      historyDateParams,
      disabledAfter(date: string) {
        const newDate = moment(date);
        const maxDate = moment(new Date()).add(-1, 'days');

        return !newDate.isSameOrBefore(maxDate);
      },
      changeTransporter() {

      },
      changeRouteSubtype() {

      },
      loadReport() {

      },
      exportReport() {

      },
      printReport() {
        const header: HTMLElement | null = document.querySelector('header.header');
        const content: HTMLElement | null = document.querySelector('.content');
        const report: HTMLElement | null = document.querySelector('#print-report');
        const sticky: NodeListOf<HTMLElement> = document.querySelectorAll('.table th');

        if (!header || !content || !report) {
          return;
        }

        header.hidden = true;
        content.style.margin = '0';
        report.style.overflow = 'visible';
        printing.value = true;
        document.title = `Отчет Форма № 1 │ ${moment(filter.date).format('DD.MM.YYYY')}`;
        sticky.forEach(e => {
          e.style.position = 'static';
          e.style.borderTop = '1px solid #dee2e6';
        });

        setTimeout(() => {
          window.print();

          header.hidden = false;
          content.style.margin = '';
          report.style.overflow = '';
          printing.value = false;
          sticky.forEach(e => {
            e.style.position = 'sticky';
            e.style.borderTop = 'none';
          });
          document.title = PROJECT_TITLE;
        }, 1000);
      },
      handleGroupObject([...group]: Array<IForm1Transporter>) {
        group.shift();

        return group;
      },
      rowspan(name: string, length: number) {
        if (length === 1) {
          return 1;
        }

        switch (name) {
          case 'index':
          case 'name':
            return length + 1;
          case 'vehicleNumberRnis':
          case 'vehicleNumberOnline':
            return length;
          default:
            return 1;
        }
      },
      filterHeaders(index: number) {
        if (index === 0) {
          return headers;
        } else {
          return notSharedHeaders.value;
        }
      },
      getForm2Query(transporterId: number) {
        return {
          transporterId,
          routeSubtypeId: filter.routeSubtype.value,
          date: moment(filter.date).format('YYYY-MM-DD'),
          timeFrom: filter.timeFrom ? moment(filter.timeFrom).format('HH:mm') : '',
          timeTo: filter.timeTo ? moment(filter.timeTo).format('HH:mm') : '',
        };
      },
    };
  },
});
